module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"path":"/builds/leksster/cocktails/locales","languages":["ru","en"],"defaultLanguage":"ru","redirect":false,"i18nextOptions":{"debug":true,"interpolation":{"escapeValue":false},"nsSeparator":false,"fallbackLng":"en"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Cocktails","short_name":"Cocktails","start_url":"/","background_color":"#f7f0eb","theme_color":"#a2466c","display":"standalone","icon":"src/assets/images/gatsby-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8b9606389179dee56d745790a84c0557"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
